export default {
    menus() {
        return [
            {
                label: '首页',
                pages: [
                    { label: '欢迎光临', path: 'home' },
                ]
            }
        ]
    },
    filter(hidden, paths) {
        const menus = this.menus()
        for (let menuIndex = menus.length - 1; menuIndex >= 0; menuIndex--) {
            const menu = menus[menuIndex]
            if (menu != null && menu.pages != null) {
                for (let pageIndex = menu.pages.length - 1; pageIndex >= 0; pageIndex--) {
                    let splice = false
                    const page = menu.pages[pageIndex]
                    if (hidden === true && !page.hidden) {
                        splice = true
                    }
                    if (hidden === false && page.hidden) {
                        splice = true
                    }
                    if (paths != null && paths.length > 0 && !paths.includes(page.path)) {
                        splice = true
                    }
                    if (splice) {
                        menu.pages.splice(pageIndex, 1)
                    }
                }
            }
            if (menu == null || menu.pages == null || menu.pages.length == 0) {
                menus.splice(menuIndex, 1)
            }
        }
        return menus
    }
}